<template>
  <div>
    <b-modal id="modal-rental" hide-header hide-footer centered>
      <div class="my-4 text-center">
        <p>
          ระบบไม่พบข้อมูลผู้แทนจำหน่าย <br />
          โปรดติดต่อ โทร xxxxxx
        </p>
        <b-button class="btn btn-submit" @click="closeModal">ตกลง</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  methods: {
    closeModal() {
      this.$bvModal.hide("modal-rental");
    }
  }
};
</script>

<style scoped></style>
